const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const cellphonePattern = /^\+?1?\d{9,15}$/

export const required = (value) => !!value || 'Este campo es necesario.'
export const email = (value) => emailPattern.test(value) || value === '' || 'Ingrese un correo electrónico válido.'
export const cellphone = (value) => cellphonePattern.test(value) || value === '' || 'Ingrese un número del formato +51987654321.'
export const maxLength = (maxLength) => (value) => value.length <= maxLength || `El campo no puede tener mas de ${maxLength} caracteres.`
export const minLength = (maxLength) => (value) => value?.length >= maxLength || `El campo debe tener mas de ${maxLength} caracteres.`
export const max = (maxValue) => (value) => value.length >= maxValue || `El campo debe tener mas de ${maxValue} caracteres.`
export const min = (minValue) => (value) => value.length >= minValue || `El campo debe tener mas de ${minValue} caracteres.`
export const equalTo = (valueName, valueToEqual) => (value) => value === valueToEqual || `El campo debe ser el mismo que ${valueName}.`
// export const igual = (minValue) => (value) => value.length >= minValue || `El campo debe tener mas de ${minValue} caracteres.`
export const containNumber = (value) => /\d/.test(value) || 'Debe usar letras, numeros y simbolos.'
export const containUpperCase = (value) => /[A-Z]/.test(value) || 'Debe usar al menos una letra mayúscula.'
export const containLowerCase = (value) => /[a-z]/.test(value) || 'Debe usar al menos una letra minuscula.'
const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
export const containSymbol = (value) => format.test(value) || 'Debe usar al menos un símbolo.'
