<template>
  <div class="w-100 h-100">
    <componentLogin />
  </div>
</template>

<script>
import componentLogin from '@/components/login/Login.vue'

export default {
  name: 'Ingresar',
  components: {
    componentLogin
  }
}
</script>
